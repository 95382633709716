

/* UserChatMessageItem.css - styling for the container that contains the text*/

body {
    font-family: 'Inter', sans-serif; /* Use Inter font for all text in the body */
}


.map-user-chat-message-item {
    position: relative;
        width: auto;
        max-width: 100%;
        min-height: 100px;
        background-color: white;
        padding: 2%;
        box-sizing: border-box;
        margin-bottom: 5%;
        border-radius: 10px;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
        padding-left: 3%;
        
}




.map-user-message-text {
    flex-grow: 1;
        margin: 0 35px;
        overflow-wrap: break-word;
        font-size: 14px;
        padding: 2%;
        margin-top: -10px;
     
}




.map-user-message-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.map-user-avatar-icon {
    position: absolute;
    top: 0.5%;
    left: 0%;
    width: 10%;
    height: auto;
    border-radius: 50%;
    padding: 1%;
}





.map-user-chat-message-item p {
    padding-top: 2%;
}


.bold-text {
    font-weight: bold;
}
