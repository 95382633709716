


body {
    font-family: 'Inter', sans-serif; /* Use Inter font for all text in the body */
    line-height: 1.6;
    background-color: white;
    overflow: hidden;
    
    
  
}


.main-container {
    position: relative;
    display: flex;

    flex-direction: column;
   
    justify-content: flex-start;
   
    padding: 2rem;

    width: 100%;
   
    max-width: 69rem;
   
    max-height: calc(100vh - 120px);
    
    
    height: 66vh;
   
    margin: 120px auto 5rem auto;
    
    border-radius: 2rem;
    
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;

    overflow: scroll;
   
}



    .main-container::before {
        content: "";
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: lightgrey;
        opacity: 0.2;
    }



    /* Style for the "Sessions" heading */
    .main-container h1 {
        width: 100%;
        position: relative;
        font-size: 1.5rem;
        font-family: 'Inter', sans-serif;
        color: #1C1C1C;
        margin-bottom: 1rem; /* Add some space below the heading */
        margin-top: 0.1rem;
        font-size: 1.1rem; /* Decreased font size */
        font-weight: 500; /* Decreased font weight */
        display: flex;
        align-items: center;
        justify-content: center;
        left: 70px;
        

    }



/* top level container for the ttile and asearch etc */

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    
}



.uploadSessions-container {
    display: flex;
    flex-direction: column;
   
    gap: 2rem; /* Increase the space between session items */
    
    max-width: 90rem; /* Set max-width for larger container size */
    height: 10rem;
    width: 97%;
    margin: 0 auto; /* Center the container */
    
}



.uploadSession-item, .uploadSessions-titles {
    display: flex;
    padding: 5.5rem; /* Increase space around */
    justify-content: space-between;
    align-items: center; /* Align items horizontally */
    justify-content: center; /* Center items vertically */
    width: 100%; /* Takes full available width */
    padding: 0rem; /* Decreased padding */
    font-size: 0.80rem;
    box-sizing: border-box; /* Added box-sizing */
    
}




.uploadSession-item {
    border-radius: 1rem;
    height: 45%; /* This now includes padding */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    
}

    .uploadSession-item > div, .uploadSessions-titles > div {
        flex: 1;
        margin: 0 1%;

    }



/* title of the header */
.title {
    font-size: 0.8rem; /* Increase font size */
    font-weight: 400; /* Increase font weight */
    color: #636363; /* Change font color */
    opacity: 0.5;
    padding: 0rem;
    padding-left: 1.5%;
    padding-bottom: 0%;
   
   
   
}



.invoice-uploadSession-type-icon,
.contract-uploadSession-type-icon,
.certificate-uploadSession-type-icon,
.BOL-uploadSession-type-icon {
    left: 10px;
    position: relative;
}



.uploadSession-status {
    display: flex;
    justify-content: center;
    align-items: center;
}


    .uploadSession-status svg {
        height: auto;
        width: 125px;
        position: relative;
        right: 12px; /* move to the right */
    }



.uploadSession-docname {
    display: flex;
    align-items: center; /* Align SVG and text vertically */
    gap: 5px; /* Create some space between SVG and text */
}

.uploadSession-pdf-icon {
    height: auto; /* Adjust size as needed */
    width: 25px; /* Adjust size as needed */
}


.uploadSession-csv-icon {
    height: auto;
    /* Adjust size as needed */
    width: 15%;
    /* Adjust size as needed */
}


.uploadSession-jpeg-icon {
    height: auto;
    /* Adjust size as needed */
    width: 15%;
    /* Adjust size as needed */
}


.uploadSession-png-icon {
    height: auto;
    /* Adjust size as needed */
    width: 15%;
    /* Adjust size as needed */
}



.uploadSession-owner-avatar {
    /* Add styles here, for example: */
    height: auto; /* Adjust as needed */
    width: 20%; /* Adjust as needed */
    margin-right: 3%; /* Add some space between the avatar and the text */
    border-radius: 50%;
    position: relative;
  
}



.uploadSession-owner {
    display: flex;
    align-items: center;
    /* Align items vertically */
    gap: 1px;
    right: 0px;
    position: relative;
   
}

.uploadSession-map-icon {
   
    height: auto;
 
    width: 27px;
 
}





.uploadSession-date {
    display: flex;
    align-items: center; /* Align items vertically */
    position: relative;
    right: 10px;
   
}

.uploadSession-date-icon {
    /* Add styles here, for example: */
    height: auto; /* Adjust as needed */
    width: 20%; /* Adjust as needed */
    margin-right: 2%; /* Add some space between the icon and the text */
}


.uploadSession-options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    right: -15px;
}




.uploadSession-download-icon {
    height: auto;
    width: 35px;
    /* Adjust the width as needed */
    cursor: pointer;
    /* Add any unique styles for the download icon here */
    margin-left: 2px;
}

.uploadSession-note-icon {
    height: auto;
    width: 20px;
    /* Adjust the width as needed */
    cursor: pointer;
    /* Add any unique styles for the note icon here */
}

.uploadSession-delete-icon {
    height: auto;
    width: 35px;
    /* Adjust the width as needed */
    cursor: pointer;
    /* Add any unique styles for the delete icon here */
}






.sessions-icon {
    height: auto;
    width: 5%; /* Adjust as needed */
    margin-right: -1%; /* Add some space between the icon and the text */

}




.uploadSession-xlsx-icon {
    width: 28px;
    height: auto;
    margin-right: 0px;
    /* Adjust spacing as needed */
}



/* Styles for the delete dialog */

.delete-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.484);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    
}



.delete-dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 27%;
    height: 28%;
    border-radius: 2rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
}

    .delete-dialog-container h2 {
        font-size: 1.5rem;
        color: #1C1C1C;
        margin-bottom: 1rem;
        margin-top: 50px;
    }

    .delete-dialog-container p {
        margin-bottom: 2rem;
    }


.delete-dialog-buttons {
    display: flex; /* Align the buttons horizontally */
    justify-content: space-between;
    width: 40%; /* Use the full width of the container */
    gap: 10%;
    padding-bottom: 5%;
}


    
.delete-all-dialog-buttons {
    display: flex;
    /* Align the buttons horizontally */
    justify-content: space-between;
    width: 40%;
    /* Use the full width of the container */
    gap: 10%;
    padding-bottom: 5%;
    margin-right: 48px;
}


.delete-dialog-container h2 {
    font-size: 1.3rem; /* Adjust as needed */
    font-weight: 600; /* Adjust as needed */
    color: #1C1C1C;
    margin-bottom: 1rem;
    text-align: center; /* Align text to the center */
}

.delete-dialog-container p {
    font-size: 0.9rem; /* Adjust as needed */
    font-weight: 300; /* Adjust as needed */
    margin-bottom: 2rem;
    text-align: center; /* Align text to the center */
}


.dialog-cancel-button {
    background-color: #9e9e9e !important; /* grey, use !important to override the .button class color */
    

}

.dialog-delete-button {
    background-color: #ab1717 !important; /* red, use !important to override the .button class color */
   
}



/* delete all sesisons svg button  */

.uploadSession-delete-all-icon {
    position: relative;
    bottom: 220%; /* Adjust as needed */
    cursor: pointer;
}



.no-sessions-message {
    display: flex;
    justify-content: center;
    
    height: 100%;
    font-size: 18px;
    /* Adjust the font size as needed */
    color: #666;
    /* Adjust the color as needed */
}



/* Manages search input ont he sesisons page */
.session-search-input {
    width: 100%;
    height: 28px;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    font-family: 'Inter', sans-serif;
    background-color: #EEEEEE;
    border-radius: 5px;
    margin-top: 0px;
    margin-bottom: 23px;
    padding-left: 30px;
}

    /* Change border when input is active/focused */
    .session-search-input:focus {
        outline: none; /* Remove the default outline */
        border: 2px solid lightgrey; /* Add a light grey border */
    }


.session-search-container {
    position: relative;
}


.session-search-icon {
    position: absolute;
    left: -3%; /* adjust this based on the size of your icon */
    top: 29%;
    transform: translateY(-50%);
    /* If your SVG doesn't have a predefined size, set the size here */
    width: 25%; /* adjust as needed */
    height: auto; /* adjust as needed */
}




/* manages laoding screen for loading sessions */

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}


.loading-screen-logo-icon {
    animation: pulse 2s infinite;
    
}


.loading-screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}



/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
    .main-container {
        width: 95%;
        padding: 1rem;
        margin-top: 60px;
        /* Reduce margin for smaller screens */
    }

    .uploadSession-item,
    .uploadSessions-titles {
        padding: 0.5rem;
        font-size: 0.75rem;
    }

    .uploadSession-pdf-icon,
    .uploadSession-date-icon,
    .uploadSession-delete-icon,
    .uploadSession-download-icon {
        width: 24px;
        /* Reduce icon sizes */
    }

    .title {
        font-size: 0.6rem;
        /* Reduce font size */
    }

    /* Add other responsive changes as needed */
}