

/* Apply Inter font to all classes */
.file-upload-widget {
    border: 2px dashed #a4d4ff;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    background-color: #f9f9f9;
    max-width: 220px;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    /* Apply Inter font */
}

.file-upload-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Inter', sans-serif;
    /* Apply Inter font */
}

.upload-icon svg {
    margin-bottom: 8px;
    color: #042e5c;
    font-family: 'Inter', sans-serif;
    /* Apply Inter font */
}



.drop-text {
    color: #a0a0a0;
    font-size: 12px;
    margin-top: -28px;
    font-family: 'Inter', sans-serif;
    /* Apply Inter font */
}

.file-support-text {
    color: #1d1d1e;
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    /* Apply Inter font */
}





.dhh-sparkles-icon {
    margin-right: 10px;
}