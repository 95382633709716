.onboarding-step-2-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.form-row {
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 85%;
    margin-bottom: 20px;
    /* Add margin-bottom for spacing between rows */
}

.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.onboarding-step-2-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.error {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
    width: 100%;
}