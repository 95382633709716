.headerpanel-container {
    width: calc(100% - 2.8%);
    margin-left: 2.8%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-bottom: 0.1px solid rgba(211, 211, 211, 0.5);
    position: fixed;
    /* Ensures header stays at the top */
    top: 0;
    right: 0;
    z-index: 8000;
    /* High z-index to stay on top */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Optional: add shadow for better visibility */
 
}

/* Add padding to the content below the header to prevent overlap */
.content {
    padding-top: 50px;
    /* Adjust according to the height of your header */
}

.sessions-icon-header-container,
.clear-session-icon-container,
.persona-icon-container { /* New container class */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.sessions-icon-header-container {
    width: 55px;
    height: auto;
}

.clear-session-icon-container {
    width: 10%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    padding-left: 1%;
    padding-right: 1%;
}

.sessions-icon-header,
.clear-session-icon {
    width: 90%;
    height: auto;
    cursor: pointer;
}

.persona-icon-container { /* New container class */
    max-width: 100%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    padding-right: 1%; /* Adjust as needed */
    left: 1%;
    right: 50px;
    flex: 0;
    position: relative;
}




  

/* Styles for the document title */



.document-title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 21px;
    width: 100%;
    max-width: 25rem;
    border-radius: 3px;
    padding: 2px;
    border-radius: 8px;
    margin-left: -29.5%;
    
}

.document-title-content {
    display: flex;
    align-items: center;
    width: 100%;
}

.pdf-icon {
    height: auto; 
    width: 24px;
    margin-right: 4px;
    padding-left: 1%;
    padding-right: 1.2%;
}





.header-map-icon {
    height: auto;
    width: 23px;
    margin-right: 0px;
    padding-left: 1%;
    padding-right: 1.2%;
}




.csv-icon {
    height: auto;
    width: 24px;
    margin-right: 0px;
    padding-left: 1%;
    padding-right: 1.2%;
}



.png-icon {
    height: auto;
    width: 24px;
    margin-right: 0px;
    padding-left: 1%;
    padding-right: 1.2%;
}



.jpeg-icon {
    height: auto;
    width: 24px;
    margin-right: 0px;
    padding-left: 1%;
    padding-right: 1.2%;
}

.document-title {
    font-family: 'Inter', sans-serif; /* Using Inter font */
    font-size: 12px;
    font-weight: 600;
}


.xlsx-icon {
    width: 24px;
    height: auto;
    margin-right: 3px;
    /* Adjusts spacing between the icon and the text */
}





.toggle-panel-button {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.toggle-panel-button:hover {
    background-color: #0056b3;
}







/* Manages trial status */

.trial-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -32%;
    position: relative;
    left: -1%;
    right: 5px;
    max-width: 500px;
    
}

.trial-countdown {
    
    margin-right: 10px;
    font-family: 'Inter', sans-serif; /* Using Inter font */
    font-size: 12px;
    font-weight: 600;
    color: black;
}

.trial-ended {
    
    color: black;
    margin-right: 10px;
    font-family: 'Inter', sans-serif; /* Using Inter font */
    font-size: 12px;
    font-weight: 600;
}

.upgrade-button {
    position: relative;
    width: 37%;
    height: auto;
    cursor: pointer;
}





/* Manages the premium svg when user is a paying active user */

.premium-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -21%;
    position: relative;
    left: 9.5%;
    right: 5px;
    max-width: 500px;
}



/* Styling the BusinessPremium SVG icon */
.business-premium-icon {
    padding-left: 0px;
    position: relative;
        width: 90%;
        height: auto;
        cursor: pointer;
    
}


