.google-login-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.google-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94%;
    max-width: 400px;
    cursor: pointer;
    padding: 12px;
    background-color: #333;
    /* Dark grey background */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    color: white;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
}

.google-login-button:hover {
    background-color: #555;
    /* Slightly lighter on hover */
}

.google-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}