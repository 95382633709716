



.map-chat-message-list-container {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    padding-left: 2%;
    padding-right: 2%;
    scroll-behavior: smooth;
    margin-top: 40px;
}
