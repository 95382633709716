.processed-doc-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    width: 45%;
    margin-top: 14px;
    height: 83vh;
    margin-left: 10px;
    margin-right: 12px;
    border-radius: 2rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow-y: auto;
    /* Changed from 'scroll' to 'auto' for better UX */
    font-family: 'Inter', sans-serif;
}

.extracted-data-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 2px solid #e4e4e4;
    margin-top: 40px;
    margin-left: 16px;
}

.extracted-data-title {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.BOL-icon-container-custom {
    display: flex;
    gap: 1px;
    /* Adjusted gap to maintain spacing between icons */
    margin-right: 18px;
    /* Align with the margin-left of the title */
    padding: 1px;
    /* Add padding inside the border for better spacing */
    border: 1px solid #9696966e;
    /* Light grey border for a modern look */
    border-radius: 8px;
    /* Rounded corners for a softer appearance */
    background-color: #f9f9f9;
    
}

.BOL-icon-container-custom:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    
    /* Enhance shadow on hover */
}

.BOL-icon-custom:hover {
    background-color: #f0f0f000;
    
}

.BOL-icon-custom {
    margin-left: 10px;
        cursor: pointer;
        border-radius: 5px;
        padding: 2px;
        margin-bottom: -10px;
        padding-right: 5px;
}


/* Specific styles for the email icon */
.icon-email-custom {
    margin-top: 6px;
    width: 20px;
    height: auto;
}