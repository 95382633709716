.stats-insights-widget {
    width: 100%;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 12px;
}




.stats-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 30px;
}

.stats-row {
    display: flex;
    justify-content: space-between;
    gap: 6px;
    /* Reduced gap between stat items */
}

.stat-item {
    flex: 0 1 calc(50% - 9px);
    /* Set width to slightly less than 50% */
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    border-radius: 4px;
    padding: 6px 4px;
    
   
}

.stat-icon {
  
    border-radius: 50%;
    padding: 5px;
    /* Slightly reduced padding */
    margin-right: 6px;
    margin-left: 10px;

}

.stat-content {
    flex-grow: 1;
    min-width: 0;
    /* Allow text to shrink if needed */
}

.stat-value {
    font-size: 14px;
    /* Slightly reduced font size */
    font-weight: 600;
    color: #333;
    white-space: nowrap;
    overflow: none;
    text-overflow: ellipsis;
}

.stat-label {
    font-size: 10px;
    color: #666;
    white-space: nowrap;
    overflow: none;
    text-overflow: ellipsis;
}