.comovis-cancel-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    width: 40%;
   
    max-height: calc(100vh - 260px);
    margin: 120px auto;
    border-radius: 2rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: auto;
}

.comovis-upgrade-success-container::before {
    content: "";
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: lightgrey;
    opacity: 0.2;
}

.comovis-cancel-title {

    text-align: center;
    font-size: 1.5rem;
    font-family: 'Inter', sans-serif;
    color: #1C1C1C;
    margin-bottom: 1rem;
    margin-top: 0.1rem;
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comovis-cancel-text {
    text-align: center;
    font-size: 16;
    color: #1C1C1C;
    margin-bottom: 9px;
    margin-top: 3%;
    width: 70%;
   
}

.comovis-button-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 40px;
}

.comovis-cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 35px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: black;
    color: white;
    border: 0.5px solid white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin-top: 50px;
}

.comovis-cancel-button:hover {
    background-color: darkgreen;
}