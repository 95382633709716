/* SubtleModal.css */

.subtle-modal {
  background: #000;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
  width: 40%;
  max-width: 450px;
  font-family: 'Inter', sans-serif;
  position: absolute;
  z-index: 1000;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
}

.subtle-modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.subtle-modal-icon {
  margin-right: 0.5rem;
}

.subtle-modal-title {
  margin: 0;
  font-size: 16px;
  margin-left: -10px;
}

.subtle-modal-content {
  font-size: 12px;
  margin-bottom: -5px;
  margin-top: -12px;
  padding: 2px;
}

.subtle-modal-close-text {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;

}

.subtle-modal-close-text:hover {
  color: #aaa;
}