

/* MapChatPanel.css */



/* styles the main panel structure */
.map-chat-panel {
    display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* This will keep the message list at the top and the input box at the bottom */
        height: 100%;
        width: 100%;
        /* It should take the full width of its parent container */
        padding: 10px;
        box-sizing: border-box;
        overflow-y: none;
        /* Allows for scrolling of the message list */
        background-color: transparent;
        position: relative;
}

