/* csv-viewer.css */
.csv-viewer-container {
    height: 92vh;
    /* Adjust this height as necessary */
    width: 100%;
    overflow: auto;
    bottom: 6.3%;
    position: relative;
    margin-left: 2%;
}

.csv-viewer-error {
    color: red;
    padding: 10px;
}

.ag-theme-alpine {
    height: 100%;
    width: 100%;
}