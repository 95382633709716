.note-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    padding-bottom: 0px;
    width: 28%;
    max-height: 80vh;
    /* Set a maximum height */
    overflow-y: auto;
    /* Enable vertical overflow only if needed */
    border-radius: 2rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    border: 0.5px solid lightgrey;
    z-index: 10000;
    font-family: 'Inter', sans-serif;
    cursor: default;
}



.note-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.484);
        backdrop-filter: blur(2px);
    /* Subtle blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    /* Ensure it's on top */
}




.note-modal-content {
    flex-grow: 1;
    position: relative;
}

.note-modal h2 {
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: auto;
}

.close-modal {
    position: absolute;
    top: 5px;
    right: -5px;
    cursor: pointer;
    font-size: 18px;
    color: #888;
}

.close-modal:hover {
    color: #333;
}

.edit-note {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.edit-note button {
    background: none;
    border: none;
    color: #1b3d62;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 14px;
}

.edit-note button:hover {
    text-decoration: underline;
}

.note-modal-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    
}

.quoted-text {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-left: 3px solid #ccc;
    background: #f9f9f9;
    font-style: italic;
    color: #555;
    font-size: 12px;
    width: 100%;
}

.note-input {
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-size: 14px;
    resize: none;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    transition: border-color 0.3s ease-in-out;
    background-color: #f9f9f9;
    /* Light background when read-only */
}

.note-input:focus {
    border: 1px solid #aaa;
    outline: none;
    background-color: #fff;
    /* White background when editing */
}

.note-timestamp {
    margin-top: 1px;
    font-size: 12px;
    color: rgb(183, 181, 181);
    text-align: left;
}