/* Add these rules to ensure the parents take full height */
html,
body {
    height: 100%;
    margin: 0;
    font-family: 'Inter', sans-serif;
    /* Apply Inter font to the entire body */
}

/* DashboardHome.css */
.dashboard-home-container {
    height: 100%;
    padding: 20px;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    /* Apply Inter font to the dashboard container */
}

.greeting-style {
    font-size: 23px;
    font-weight: bold;
    color: #181818;
    margin-bottom: 20px;
    margin-top: 40px;
    margin-left: 9px;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
    /* Apply Inter font to the greeting text */
}


.sub-greeting {
    font-family: 'Inter', sans-serif;
    margin-top: -25px;
    /* Adjust spacing as needed */
    color: #414141;
    /* Adjust color as needed */
    margin-left: 7px;
    font-size: 12px;
}

.dashboard-widgets {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    /* Apply Inter font to the widgets container */
}


.widget {
    padding: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 30%;
    text-align: center;
    border-radius: 2%;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    height: 300px;
    overflow-x: hidden;
    /* Prevent horizontal overflow */
    overflow-y: auto;
    /* Enable vertical scrolling if content overflows */
}


.widget-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
}

.widget h2 {

    align-items: center;
}
