/* ContextMenu.css */

.context-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    z-index: 1000;
    /* Place it above other items */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    /* Set the font to Inter */
}

.context-menu-option {
    padding: 10px 19px;
    cursor: pointer;
    color: #333;
    font-size: 13px;
    /* Set the desired font size */
    font-weight: 500;
    /* Adjust the font weight */
    transition: background-color 0.2s;
    display: flex;
    /* Makes it a flex container */
    align-items: center;
    /* Aligns items vertically centered */
    gap: 10px;
    /* Space between the SVG icon and the text */
}

.context-menu-option:hover {
    background-color: #f7f7f7;
}

.context-menu-option svg {
    width: 24px;
    /* Ensuring consistent size */
    height: 24px;
    /* Ensuring consistent size */
    fill: #333;
    /* Color of the SVG, adjust if necessary */
    flex-shrink: 0;
    /* Prevents the icon from resizing */
}

/* Remove any extra margin/padding from the add-notes-icon */
.add-notes-icon {
    width: 14px;
    /* Match other icons */
    height: auto;
    /* Match other icons */
    fill: inherit;
    margin: 0;
    /* Ensure no extra margin */
    padding: 0;
    /* Ensure no extra padding */
    box-sizing: content-box;
    /* Ensures the width/height are not affected by padding */
}