body {
    font-family: 'Inter', sans-serif;
    /* Use Inter font for all text in the body */
    line-height: 1.6;
}



.feedback-main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    width: 100%;
    max-width: 63rem;
    max-height: calc(100vh - 200px);
    margin: 4rem auto 8rem auto;
    border-radius: 2rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: auto;
}

.feedback-main-container::before {
    content: "";
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: lightgrey;
    opacity: 0.2;
}

.feedback-main-container h1 {
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    font-family: 'Inter', sans-serif;
    color: #1C1C1C;
    margin-bottom: 1rem;
    margin-top: 0.1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feedback-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.feedback-loading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

