/* ChatInputBox.css */

.chat-input-container {
    position: absolute;
    min-width: 75%;
    /* Ensures the container is never too narrow */
    max-width: 85%;
    /* Prevents the container from becoming too wide on large screens */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;
    bottom: 8px;
    left: 100px;
    /* Adjust as necessary, possibly using media queries for responsiveness */
}

.chat-input {
    width: 100%;
    /* Changed from 150% to 100% */
    padding: 4%;
    box-sizing: border-box;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    resize: none;
    max-height: 150px;
    /* Set a minimum height */
    font-family: 'Inter', sans-serif;
    overflow: hidden;
    /* Prevent scrollbar from appearing */
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: none;
    /* Remove border */
    padding-right: 70px;
    /* Adjusted from 90px to 20px */
    overflow: auto;
    font-size: 12px;
}


.chat-input:disabled {
    background-color: #f0f0f0;
    /* Light grey background */
    color: #e6e6e6;
    /* Grey text */
    cursor: not-allowed;
    /* Show not-allowed cursor */
    filter: blur(0.5px);
    /* Apply blur effect */
}

.chat-send-button {
    position: absolute;
    right: 5px;
    /* Adjusted from -23% to 15px inside the container */
    bottom: 25px;
    /* Adjusted for better alignment */
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 5px;
  
}


.stop-streaming-icon {
   
    width: 12px;
    /* Adjust the size if necessary */
    height: auto;
    /* Adjust the size if necessary */
    position: absolute;
        right: 40px;
        margin-left: 5px;
        margin-top: 10px;
}



.chat-input:focus {
    outline: none;
    /* This will remove the border when the input is focused */
}





.loader-container {
    position: absolute;
    bottom: 28px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-end;
}


.chat-input.is-streaming {
    box-shadow: 0 0 5px #4a90e2;
    border-color: #4a90e2;
    transition: all 0.3s ease-in-out;
}

@keyframes gentle-pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}


