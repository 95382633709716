.dashboard-container {
    display: block;
    height: 100vh;
    background-color: white;
}

.dashboard-main {
    display: block;
    margin-left: 2.8%;
    height: 100%;
}

.dashboard-content {
    display: block;
    height: calc(100% - 50px);
}
