


/* SignupPageStyles.css */

body {
    background-color: #000; 
    font-family: 'Inter', sans-serif; 
min-height: 100vh;
}


.signup-page {
    background-color: #000; 
    font-family: 'Inter', sans-serif; 
    color: #FFF; 
    text-align: center; 
    padding-top: 50px; 
    min-height: 100vh;
}

    .signup-page .logo {
        height: auto; 
        margin-bottom: 0%; 
    }



   

