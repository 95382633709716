



.chat-message-list-container {
    max-height: calc(100vh - 160px);
    height: 80vh;
    overflow-y: auto;
    padding-left: 2%;
    padding-right: 2%;
    scroll-behavior: smooth;
    padding-top: 1%;
    
}
