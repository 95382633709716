.image-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 105%;
}

.full-size-image {
    max-width: 100%;
    height: auto;
}

