.intro-chat-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  padding-bottom: 40px;
  width: 65%;
  margin-top: 14px;
  height: 290vh;
  margin: auto;
  border-radius: 2rem;
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  overflow: none;
  border: 0.5px solid lightgrey;
  
}

.intro-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1px;
}

.intro-text-container {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: -2px;
}

.intro-modal-btn {
  margin: auto;
  margin-top: 10px;
}

.intro-list {
  list-style-type: none;
  padding-left: 1.5em;
}

.intro-list li {
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 10px;
  /* Space between bullet points */
}

.intro-list li::before {
  content: '→';
  /* Unicode arrow character */
  position: absolute;
  left: 0;
  color: #666;
}