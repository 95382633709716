


.account-main-container {
    position: relative;
    
        display: flex;
    
        flex-direction: column;
    
        justify-content: flex-start;
    
        padding: 2rem;
    
        width: 100%;
        
        max-width: 63rem;
    
        max-height: calc(100vh - 120px);
    
        margin: 120px auto 5rem auto;
    
        border-radius: 2rem;
    
        box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    
        background: #ffffff;
    
        overflow: auto;
}

    .account-main-container::before {
        content: "";
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: lightgrey;
        opacity: 0.2;
    }

    .account-main-container h1 {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        font-family: 'Inter', sans-serif;
        color: #1C1C1C;
        margin-bottom: 1rem;
        margin-top: 0.1rem;
        font-size: 1.1rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }

.account-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}


.account-avatar-container {
    width: 100px;
    height: 100px;
    border: 1px solid rgba(211, 211, 211, 0.2); /* Adjusted for transparency */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-top: 20px;
}



    .account-avatar-container svg.AccountDefaultAvatar {
        width: 146px; /* You can adjust this value */
        height: auto; /* You can adjust this value */
    }



.account-avatar-container {
    position: relative;
    /* This will ensure that the overlay will be relative to this container */
}

.avatar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* dark grey and 50% transparent */
    opacity: 0; /* Initially hidden */
    cursor: pointer; /* Show pointer cursor on hover */
    transition: opacity 0.3s; /* Smooth transition for opacity */
}

.account-avatar-container:hover .avatar-overlay {
    opacity: 1; /* Show the overlay when avatar container is hovered */
}


.avatar-overlay {
    font-family: 'Inter', sans-serif; /* Use the Inter font */
    font-size: 12px; /* Decrease the font size. Adjust according to your needs. */
    color: white; /* Make the text color white */
}


.plans-membership-container h2 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
    
}






.user-avatar-img {
    width: 100%; /* Adjust width as per your design */
    height: auto; /* This will maintain the aspect ratio */
    object-fit: contain; /* This ensures the image is resized to fit the container while retaining its aspect ratio */
    border-radius: 50%; /* This will make the image circular, if that's what you desire */
}








/* Below manages the form fields in accounts page */

.account-content-container {
    display: flex;
    gap: 2rem;
}

.account-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    flex: 1; 
    
    /* This will allow the form container to take up the remaining space to the right of the avatar container */
}

    .account-form-container .detail-inputs {
        display: flex;
        gap: 20px;
    }

        .account-form-container .detail-inputs label {
            flex: 1;
        }

.account-input-label {
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
    line-height: 150%;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-align: left;
}

.account-input-field {
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    font-family: Inter, sans-serif;
}


