

/* Modal content container */
.comovis-dispatch-content {
    background: #ffffff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 440px;
    font-family: 'Inter', sans-serif;
    position: relative;
    height: auto;
}

/* Modal title */
.comovis-dispatch-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.75rem;
    font-family: 'Inter', sans-serif;
}



/* Style for the paragraph in the modal */
.comovis-dispatch-content p {
    font-size: 12px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 1rem;
}

/* List of options container */
.comovis-dispatch-options-list {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
}

/* Individual option item */
.comovis-dispatch-option-item {
    margin-bottom: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    padding: 0.5rem;
    border-radius: 0.3rem;
}

/* Hover effect for option item */
.comovis-dispatch-option-item:hover {
    background-color: #f0f0f0;
}

/* Option title (main header) */
.comovis-dispatch-option-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.25rem;
}

/* Option description (subheading) */
.comovis-dispatch-option-description {
    font-size: 14px;
    color: #666;
}

.comovis-dispatch-close-button {
    margin: auto;
}