.upgrade-success-page {
    background-color: #000000;
    /* Set the background to black */
    font-family: 'Inter', sans-serif;
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comovis-upgrade-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 90px;
}

.upgrade-comovis-logo {
    max-width: 150px;
    /* Adjust size as needed */
    margin-bottom: 10px;
    /* Space between logo and container */
}

.comovis-upgrade-success-container {
    max-width: 350px;
    padding: 40px;
    background-color: #1c1c1c;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.comovis-success-title {
    text-align: center;
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 1rem;
}

.comovis-success-text {
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 1rem;
    line-height: 1.4;
}

.comovis-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: -10px;
}




.comovis-error-title {
    text-align: center;
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 800;
    margin-top: 100px;
}


