/* LandingPageStyles.css */

body {
    background-color: #000;
    font-family: 'Inter', sans-serif;
}

.landing-page {
    background-color: #000;
    color: #FFF;
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* Added to stack elements vertically */
    justify-content: center;
    align-items: center;
}

.landing-logo {
    height: auto;
    margin-bottom: 0px;
    margin-top: 50px;
}

.landing-container {
max-width: 350px;
    margin: auto;
    padding: 40px;
    background-color: #1c1c1c;
    /* Slightly different dark grey background */
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
    
}

.welcome-text {
    margin-bottom: 20px;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.4;
    word-wrap: break-word;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.button {
    color: #FFF;
    background-color: #333;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.button:hover {
    background-color: #555;
}