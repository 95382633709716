

body {
    font-family: 'Inter', sans-serif; /* Use Inter font for all text in the body */
}




/* AIChatMessageItem.css */
.map-ai-chat-message-item {
    position: relative;
        width: auto;
        max-width: 100%;
        min-height: 100px;
         background-color: #F7F9FB;
        padding: 2%;
        box-sizing: border-box;
        margin-bottom: 5%;
        border-radius: 10px;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
        padding-left: 3%;
        font-family: 'Inter', sans-serif;
}


.map-message-header {
    display: flex;
        justify-content: space-between;
        align-items: flex-start;
        /* Align items to the start, not center */
        padding-top: 10px;
        /* Add some padding at the top, adjust as needed */
}

.map-app-icon {
    position: absolute;
    top: 5%;
    left: 2%;
    width: 5%;
    height: auto; /* and this */
}


.map-copy-icon {
    /* Adjust as needed */
    position: absolute;
    top: 0px;
    right: 1.5%;
    cursor: pointer;
}

.map-ai-chat-message-item p {
    padding-top: 1%; /* push the text down, below the icons */
}


.map-ai-message-text {
    flex-grow: 1;
        margin: 0 35px;
        /* Only apply horizontal margins */
        overflow-wrap: break-word;
        font-size: 13px;
        padding: 0.7%;
        font-family: 'Inter', sans-serif;
}
