

.password-reset-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: black;
}

.password-reset-logo {
    margin-bottom: 0px;
    margin-top: 70px;
}

.password-reset-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 350px;
    margin: auto;
    padding: 20px;

}

.password-reset-input-label {
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
    line-height: 150%;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-align: left;
}

.password-reset-input-field {
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    font-family: Inter, sans-serif;
}

.password-reset-confirm-button {
    display: block;
    margin: 0 auto;
    background-color: black;
    
}

.password-reset-instructions {
    color: white;
    font-size: 14px;
    margin-top: 90px;
    margin-bottom: -200px;
    text-align: center;
    max-width: 350px; /* You can adjust this based on your design preference */
    margin-left: auto;
    margin-right: auto;
    font-family: Inter, sans-serif;
    line-height: 1.5; 
    font-weight: 500;
}
