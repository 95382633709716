



/* LoginPageStyles.css */

body {
    background-color: #000; 
    font-family: 'Inter', sans-serif; 
  
    height: 100vh;
}

.login-page {
    background-color: #000; 
    color: #FFF; 
    text-align: center; 
    padding-top: 50px; 
    height: 100vh;
}



.logo {
    height: auto; 
    margin-bottom: 5px; 
}
