/* OnboardingModalStyles.css */

/* Full page overlay that acts as the modal backdrop */
.onboarding-full-page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.484);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 2rem;
    z-index: 100000;
    flex-direction: column;
}

/* The modal box itself */
.onboarding-modal-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #000000;
    font-size: 15px;
    color: #ffffff;
    width: 45%;
    text-align: center;
    border: 1px solid #212020;
}

/* Logo inside the modal */
.onboarding-modal-logo {
    max-width: 100px;
    margin-bottom: 20px;
}

/* Title inside the modal */
.onboarding-modal-title {
    color: #ffffff;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

/* Button inside the modal */
.onboarding-modal-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    margin-bottom: 35px;
}

.onboarding-modal-button:disabled {
    background-color: grey;
    cursor: not-allowed;
}


/* Step indicator */
.step-indicator {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 4px;
}