

.upgrade-plan-main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    width: 100%;
    max-width: 67rem;
    max-height: calc(100vh - 100px);
    margin: 120px auto;
    border-radius: 2rem;
   
    background: #ffffff;
    overflow: auto;
    margin-top: 48px;

}



    .upgrade-plan-main-container::before {
        content: "";
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: lightgrey;
        opacity: 0.2;
    }

    .upgrade-plan-main-container h1 {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        font-family: 'Inter', sans-serif;
        color: #1C1C1C;
        margin-bottom: 1rem;
        margin-top: 0.1rem;
        font-size: 1.1rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
    }

.upgrade-plan-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: -35px;
}


.upgrade-plan-listing-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    flex-direction: row;
    

}




.upgrade-plan-single {
    flex: 1;
    border: 1px solid #ccc;
    padding: 1rem;
    margin: 1rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    height: 70%;

    
}

    .upgrade-plan-single h2 {
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 45px;
        font-weight: 600;
        font-family: 'Inter', sans-serif;
    }

    .upgrade-plan-single p {
        margin-top: 0.5rem; /* Reduce this to move the paragraph higher */
        text-align: center;
        font-weight: 500;
        margin-top: 10px;
    }



    .upgrade-plan-single .premium-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }



    .upgrade-plan-single .free-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }




.custom-list {
    list-style: none;
    padding-left: 0;
}

    .custom-list li {
        display: flex;
        align-items: center;
    }

        .custom-list li svg {
            margin-right: 1%;
            min-width: 5%;
            min-height: auto;
            flex-shrink: 0;
        }






/* Container for the new buttons */
.upgrade-page-buttons-container {
    display: flex;
    justify-content: center;
    width: 45%;
    margin-top: 2%;
    margin-bottom: 40px;
    padding-bottom: 5px;
    margin-left: 30%;
}

/* Button for upgrading */
.button-to-upgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 35px;
    padding: 8px 16px;
    border-radius: 8px;
   
    border: 0.5px solid white;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: -23px;
    margin-top: -2px;
}


/* Price Display Styles */
.price-display {
    text-align: center;
    font-family: 'Inter', sans-serif;
    margin-bottom: 20px;
}

.price-circle {
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    width: 100px;
    /* Adjust as needed */
    height: 100px;
    /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    /* Adjust font size for the price */
    font-weight: 500;
    margin: 0 auto;
}

.price-period {
    margin-top: 10px;
    font-size: 12px;
    /* Adjust font size for the period text */
    color: #000;
    font-weight: 500;
}


.premium-confirmation-icon {
    margin-bottom: -30px;
    margin-top: -10px;
   
}