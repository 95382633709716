.onboarding-step-4-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #00000000;
    border-radius: 8px;
}

.ai-powered-workflow-section {
    text-align: center;
    margin-bottom: 20px;
}

.agreement-section {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    max-width: 700px;
    text-align: left;
    margin-right: 100px;
}

.agreement-container input {
    margin-right: -100px;
}

.agreement-container label {
    text-align: left;
}

.agreement-container label a {
    color: #ffffff;
    text-decoration: underline;
}

.button-section {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.onboarding-button-page4 {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.onboarding-button-page4:disabled {
    background-color: grey;
    cursor: default;
}