.dh-recent-sessions-widget h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: 500;
}

.dh-loading,
.dh-error {
    text-align: center;
    color: #666;
    padding: 20px;
}

.dh-no-sessions {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background-color: #f8f8f8;
    border-radius: 4px;
    color: #666;
    height: 200px;
}

.dh-sessions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dh-session-item {
    display: flex;
    align-items: center;
    padding: 15px;
    margin: 5px 0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 45px;
}

.dh-session-item:hover {
    background-color: #f0f0f0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dh-session-item:last-child {
    border-bottom: none;
}

.dh-session-icon {
    width: 30px;
    height: auto;
    border-radius: 20%;
    background-color: #f0f0f0;
    margin-right: 15px;
    flex-shrink: 0;
}

.dh-session-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.dh-session-info h3 {
    font-size: 13px;
    margin: 0 0 5px 0;
    color: #333;
    font-weight: 500;
    text-align: left;
}

.dh-session-date-tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dh-session-info p {
    font-size: 0.9rem;
    color: #666;
    margin: 0;
    text-align: left;
}

.dh-session-type-icon {
    margin-left: 10px;
    width: auto;
    height: auto;
    position: relative;
    top: -30px;
}

