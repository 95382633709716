

/* ConfirmEmailPageStyles.css */

body {
    background-color: #000; 
    font-family: 'Inter', sans-serif; 
}

.confirm-email-page {
    background-color: #000;
    font-family: 'Inter', sans-serif; 
    color: #FFF; 
    text-align: center; 
    padding-top: 2%; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    height: 100vh;
}

    .confirm-email-page .logo {
        height: auto; /* adjust as needed */
        margin-bottom: 1%; /* space below the logo */
        margin-top: -15%;
    }

    .confirm-email-page .modal {
        margin-top: 60px; /* space above the modal */
    }
