

.password-reset-trigger-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.password-reset-trigger-logo {
    margin-bottom: 0px;
    margin-top: 70px;
}

.password-reset-trigger-instructions {
    color: white;
    font-size: 14px;
    margin-top: 90px;
    margin-bottom: -200px;
    text-align: center;
    max-width: 350px; /* You can adjust this based on your design preference */
    margin-left: auto;
    margin-right: auto;
    font-family: Inter, sans-serif;
    line-height: 1.5;
    font-weight: 500;

}

.password-reset-trigger-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 350px;
    margin: auto;
    padding: 20px;
}

.password-reset-trigger-input-label {
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
    line-height: 150%;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-align: left;
}

.password-reset-trigger-input-field {
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    font-family: Inter, sans-serif;
}

.password-reset-trigger-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.password-reset-trigger-cancel-button {
    background-color: grey; /* Red for cancel */
    color: white;
}

.password-reset-trigger-send-button {
    background-color: black; /* Green for confirm */
    color: white;
}

.password-reset-trigger-message {
    color: white;
    font-size: 14px;
    margin-top: 90px;
    margin-bottom: -200px;
    text-align: center;
    max-width: 350px; /* You can adjust this based on your design preference */
    margin-left: auto;
    margin-right: auto;
    font-family: Inter, sans-serif;
    line-height: 1.5;
    font-weight: 500;
}
