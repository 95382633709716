.question-suggestions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;
    /* Adjust this value to move the container higher or lower */
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.question-container {
    width: 100%;
    text-align: center;
}

.question-box {
    padding: 10px 20px;
    margin-bottom: 2%;
    background-color: #ffffff;
    /* White background for each question */
    border: 1px solid #dddddd;
    /* Thin grey border */
    border-radius: 10px;
    /* Rounded corners */
    cursor: pointer;
    /* Change the cursor to a hand when hovering over the box */
    transition: background-color 0.6s ease;
    /* Optional: smooth color transition */
    font-family: 'Inter', sans-serif;
    /* Using Inter font */
    font-size: 13px;
    /* Decreased font size */
    max-width: 50%;
    /* Maximum width of the question box */
    word-wrap: break-word;
    /* Break the words as needed to prevent overflow */
    margin: 0 auto;
    /* Center align the question boxes */
}

.question-box:hover {
    background-color: #f0f0f0;
    /* Slightly darker color when hovered */
}

.suggested-notice {
    display: block;
    /* Ensure it takes up the full width */
    margin: 10px auto;
    /* Center the notice horizontally */
    margin-bottom: 3%;
    /* Space between the notice and the questions */
    width: 55%;
    /* Optional: specify a width if needed */
}