.chat-panel {
    display: flex;
    flex-direction: column;


justify-content: flex-end;
    width: 50%;
    background-color: transparent;
    box-sizing: border-box;
    overflow-y: none;
    position: relative;
    padding: 10px;


}



.mistake-style {
   font-size: 9px;
   margin: auto;
   margin-top: -15px;
   color: rgb(76, 76, 76);

}


.scroll-to-bottom-container {
    position: fixed;
    right: 350px;
    bottom: 120px;
    cursor: pointer;
    z-index: 1000;
    background-color: rgb(162, 255, 0);
    border-radius: 50%;
    padding: 10px;

   
  
}

.scroll-to-bottom-container svg {
    width: 24px;
    height: 24px;
}