.account-status-fields {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;

    border-radius: 8px;
    max-width: 400px;
}

.status-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.status-field:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.status-field label {
    font-weight: 600;
    color: #495057;
}

.status-field span {
    color: #212529;
    font-weight: 500;
}

@media (max-width: 480px) {
    .status-field {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }
}