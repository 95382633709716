.signup-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.signup-modal-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px;
    text-align: center;
}

.signup-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color: black;
    cursor: pointer;
    display: none;
}

.signup-modal-close:hover {
    color: #555;
}

.signup-center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.demo-signup-button {
    margin: 20px auto 0 auto;
    text-align: center;
    width: 70%;
}