.side-panel {
   
    width: 2.7%;
    height: 100vh;
    background-color: black;
    color: white;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 1rem;
    z-index: 90000;
    box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
}

.side-panel-logo {
    width: 24px;
    margin-bottom: 20px;
    /* Reduced spacing below the logo */
    margin-top: 1px;
    /* Adjusted space above the logo */
}

.second-menu-container {
  margin-bottom: 60px;

}

.first-menu-container {
    margin-top: 10px;

}



.menu-item {
    width: 100%;
    height: 35px;
    padding: 1px 0;
    /* Reduced padding for less gap */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    border-radius: 8px;
}


.dashboard-menu-icon {
        width: 25px;
        height: 25px;
       
}

.menu-item:hover {
    background-color: #221f1f;
}

/* Adjust the absolute positions to be closer */
.insights-container {
    bottom: 300px;
}

.sessions-container {
    bottom: 280px;
}

/* Reduced gap */
.explore-container {
    bottom: 260px;
    
}

/* Reduced gap */
.feedback-container {
    bottom: 240px;
}

/* Reduced gap */
.profile-container {
    bottom: 220px;
}

/* Reduced gap */
.logout-container {
    bottom: 200px;
}

/* Reduced gap */

.menu-icon {
    width: 25px;
    height: 25px;
}

.profile-icon,
.user-uploaded-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 8px;
}

.menu-spacer {
    height: 62%;
    /* Significantly reduced height */
    width: 100%;
}

.menu-item-active,
.menu-item-active:hover {
    background-color: #221f1f;
    /* Dark grey to indicate selection */
  
}