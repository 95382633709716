.persona-icon {
    display: flex;
    align-items: center;
    padding: 2.5px 8px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    /* Reduced font size */
    background-color: var(--background-color);
    color: var(--text-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    margin-right: 12px;
   
}

.persona-icon img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.persona-text {
    flex: 1;
    text-transform: capitalize;
    /* Ensure the first letter of each word is capitalized */
}