.map-container {
    height: 100%;
    width: 100%;
    position: relative;
}



/* MapContainer.css */



.icon-size {
    width: 30px;
    height: 30px;
}

.leaflet-marker-icon {
    transition: transform 0.3s ease;
    transform-origin: center;
}