/* Full-page modal overlay */
.export-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(211, 211, 211, 0.5);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

/* Modal content container */
.export-modal-content {
    background: #ffffff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    width: 60%;
    max-width: 450px;
    font-family: 'Inter', sans-serif;
    position: relative;
    height: auto;
}

/* Modal title */
.export-modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.75rem;
}

/* Export options list */
.export-options-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Export option item */
.export-option-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0.4rem 0.6rem;
    border-radius: 0.3rem;
    margin-bottom: 0.3rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    position: relative;
}

.export-option-item:hover {
    background-color: #f0f0f0;
}

/* Styling the icons */
.export-option-icon {
    width: 16px;
    /* Adjust size as needed */
    height: 16px;
    /* Adjust size as needed */
    margin-right: 0.5rem;
    /* Space between icon and text */
}

/* Arrow icon indicating submenu, only for items with submenus */
.export-option-has-submenu::after {
    content: '>';
    font-size: 12px;
    color: #999;
    margin-left: auto;
}

/* Submenu for nested export options */
.export-option-has-submenu {
    position: relative;
}

.export-submenu {
    position: absolute;
    left: 100%;
    top: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 0.4rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: none;
    min-width: 160px;
}

.export-option-has-submenu:hover .export-submenu {
    display: block;
}

/* Submenu item */
.export-submenu-item {
    font-size: 12px;
    padding: 0.4rem 0.6rem;
    cursor: pointer;
    white-space: nowrap;
}

.export-submenu-item:hover {
    background-color: #f0f0f0;
    color: #007bff;
}

/* Close button */
.export-modal-close-button {
    display: block;
    margin: 1rem auto 0 auto;
    padding: 0.3rem 0.6rem;
  
}



.export-option-icon {
    width: 22px;
    height: auto;
}

