.formfieldslogin-container {
    max-width: 350px;
    margin: auto;
    padding: 40px;
    background-color: #1c1c1c;
    /* Slightly different dark grey background */
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
    height: 52vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formfieldslogin {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.input-label {
    color: rgba(255, 255, 255, 1);
    /* White text for labels */
    font-size: 12px;
    line-height: 150%;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-align: left;
}

.input-field {
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    font-family: Inter, sans-serif;
}

.login-button {
    display: block;
    margin: 0 auto;
    width: 100%;
}

.error {
    color: red;
    font-size: 0.8em;
    margin-top: 0.5em;
}

.signup-text {
    margin-top: 5px;
    font-size: 14px;
    color: #FFF;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: -5px;
    font-weight: 800;
}

.signup-text:hover {
    color: #aaa;
}

.forgot-text {
    margin-top: 19px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}