.google-signup-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.divider {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.divider span {
    font-size: 14px;
    color: #666;
    padding: 0 10px;
    /* Removed background */
}

.divider::before,
.divider::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
}

.google-signup-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94%;
    max-width: 400px;
    cursor: pointer;
    padding: 12px;
    background-color: #333;
    /* Dark grey */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    color: white;
}

.google-signup-button:hover {
    background-color: #555;
    /* Slightly lighter grey on hover */
}

.google-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}