.chat-panel-header-bar {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px;
    width: 94%;
    margin-top: -8px;
    height: auto;
    /* Adjust height if needed */
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.9);
    /* White with 90% opacity */
    font-family: 'Inter', sans-serif;
    z-index: 10;
    /* Ensure it's above the content */
}

.chat-panel-header-bar.bol-panel-open {
    background: rgba(255, 255, 255, 0.9);
    /* Same as above, slightly transparent white */
    padding: 3rem;
    position: relative;
    top: 50px;
}

.chat-panel-header-bar.scrolled {
    opacity: 0.7;
    /* Make the bar slightly transparent when scrolled */
    transform: scale(0.9);
    /* Scale down the bar */
}

.toggle-doc-visibility-btn {
    margin: 0;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.BOL-button-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    vertical-align: middle;
}