.onboarding-step-1-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.onboarding-step-1-selections {
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 50px;
    /* Add space between the selection containers */
}

.selection-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
    width: 220px;
    /* Adjust as needed */
    height: 200px;
    /* Adjust as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    transition: border 0.3s;
    /* Smooth transition for the border */
}

.selection-container.selected {
    border: 3px solid #2e8931;
    /* Thicker border when selected */
}

.selection-container h3 {
    margin: 10px 0;
    color: #000;
    /* Set the color to black */
}

.selection-container p {
    color: #666;
}

