.formfieldssignup-container {
    max-width: 350px;
    margin: auto;
    padding: 40px;
    background-color: #1c1c1c;
    /* Dark grey background */
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
   height: 55vh;
    
}

.formfieldssignup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.input-label {
    color: rgb(255, 255, 255);
    font-size: 12px;
    line-height: 150%;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-align: left;
}

.input-field {
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    font-family: Inter, sans-serif;
}

.signup-button {
    display: block;
    margin: 0 auto;
    width: 100%;
}

.error {
    color: red;
    font-size: 0.8em;
    margin-top: 0.5em;
}


.login-link-text {
    margin-top: 20px;
    font-size: 14px;
    color: #FFF;
    text-align: center;
}

.login-link {
    color: #FFF;
    text-decoration: underline;
    cursor: pointer;
}

.login-link:hover {
    color: #aaa;
}