body {
    font-family: 'Inter', sans-serif;
    /* Use Inter font for all text in the body */
}

/* AIChatMessageItem.css */
.ai-chat-message-item {
    position: relative;
    width: auto;
    max-width: 98%;
    min-height: 100px;
    background-color: #F7F9FB;
    padding: 2%;
    box-sizing: border-box;
    margin-bottom: 5%;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    padding-left: 3%;
}



.ai-chat-message-item:first-of-type {
    margin-top: 8px;
    /* Adjust this value as needed */
}


.ai-chat-message-item:last-of-type {
    margin-bottom: 12%;
    /* Larger bottom margin for the last item */
}


.message-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 10px;
}

.app-icon {
    position: absolute;
    top: 1%;
    left: 1.5%;
    width: 3.5%;
    height: auto;
}



.ai-chat-message-item p {
    padding-top: 1%;
}

.ai-message-text {
    flex-grow: 1;
    margin: 0 35px;
    overflow-wrap: break-word;
    font-size: 12.5px;
    padding: 0.7%;
    margin-top: -25px;
}

/* Add styles for the feedback and share icons container */
.icon-container-custom {
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;
    margin-left: 25px;
    padding: 2px;
    
        width: 16%;
        margin-bottom: 10px;
}

.icon-custom {
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
    padding: 3px;
}

.icon-custom:hover {
    background-color: #f0f0f0;
}

.icon-positive-feedback-custom {
    fill: #28a745;
    width: 18px;
}

.icon-negative-feedback-custom {
    fill: #dc3545;
    width: 18px;
}

.icon-share-custom {
    fill: #007bff;
    width: 18px;
}




.icon-copy {
    cursor: pointer;

        border-radius: 5px;
     
    
}

.icon-copy:hover {
    background-color: #f0f0f0;
}





.success-copy {
   width: 33px;
   height: auto;
   margin-top: 2px;
}

/* Styles for the feedback overlay and modal */
.feedback-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(211, 211, 211, 0.5);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.give-feedback-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 20%;
    height: auto;
    border-radius: 2rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    position: relative;
}

.feedback-comment {
    width: 90%;
    height: 100px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 14px;
    resize: none;
    font-family: 'Inter', sans-serif;
}

.feedback-checkboxes {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.feedback-checkboxes label {
    width: auto;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.feedback-checkboxes input {
    margin-right: 10px;
}

.feedback-submit-button {
    width: 30%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #000000;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.feedback-submit-button:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
    color: #000000;
}

.feedback-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.feedback-paragraph {
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
}

.feedback-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #000;
}

.feedback-close-button:hover {
    color: #230202;
}

.submitted-message {
    font-size: 14px;
    color: #000000;
    margin-top: 10px;
    text-align: center;
}


/* AIChatMessageItem.css */

.page-number {
    display: inline-block;
    margin: 0 1px;
    padding: 3px 7px;
    border-radius: 40%;
    background-color: #f0f0f0;
    color: #333;
    font-size: 9px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.page-number:hover {
    background-color: #e0e0e0;
    border-color: #ccc;
}

/* AIChatMessageItem.css */
.active-note-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    cursor: pointer;
    position: relative;
    top: 4px;
    /* Adjust as needed */
}

.active-note-icon:hover::after {
    content: attr(title);
    position: absolute;
    background: #333;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    top: -24px;
    left: 0;
    white-space: nowrap;
    z-index: 1000;
    font-size: 12px;
}



/* AIChatMessageItem.css or your global CSS file */

/* Highlighted text style */
.highlighted-text {
    background-color: rgb(246, 246, 183);
    /* Highlight color */
    font-weight: 500;
    /* Make the text bold */
    padding: 2px;
    /* Add some padding for better visibility */
    border-radius: 3px;
    /* Round the corners of the highlight */
    text-decoration: underline;
    /* Add underline */
    text-decoration-color: rgba(67, 67, 67, 0.3);
    /* Set underline color to be subtle */
    text-decoration-thickness: 0.8px;
    /* Control the thickness of the underline */
    text-underline-offset: 2px;
    /* Set the distance between the text and the underline */
    cursor: pointer;
}

.ai-message-text {
    /* Consider using white-space: normal to ensure natural text wrapping */
    white-space: normal;

}