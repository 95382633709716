@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body,
button,
input,
select,
textarea {
    font-family: 'Inter', sans-serif;
}

.draft-email-ai {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
}

.draft-email-button {
   margin-top: 60px;
 
}



.generate-email-sparkles-icon {
    margin-right: 8px;
    /* Adjust space between the icon and text */
    color: white;
    /* Make the icon white */
    vertical-align: middle;
    /* Align icon vertically with the text */
    width: 18px;
    height: auto;
}


.custom-xx-copy-icon {
    margin-right: 10px;
}

.mr-send-button {
   margin-right: 10px;
}

.draft-email-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.draft-email-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(2px);
}

.draft-email-modal-content {
    background: #ffffff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 440px;
    font-family: 'Inter', sans-serif;
    position: relative;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
}

/* CSS for the larger modal in the last step */
.draft-email-modal-content.large {
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    width: 95%;
    max-width: 600px;
    font-family: 'Inter', sans-serif;
    position: relative;
    height: auto;
    max-height: 84vh;
   overflow: hidden;
}

.draft-email-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

.draft-email-modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.75rem;
    font-family: 'Inter', sans-serif;
}

.draft-email-modal-description {
    font-size: 12px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 1rem;
}

.draft-email-template-list {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
}

.draft-email-template-item {
    margin-bottom: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    padding: 0.5rem;
    border-radius: 0.3rem;
    text-align: left;
    /* Aligns the text to the left */
}

.draft-email-template-item:hover {
    background-color: #f0f0f0;
}

.draft-email-template-item.selected {
    background-color: #e0e0e0;
}

.draft-email-template-name {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.25rem;
}

.draft-email-template-description {
    font-size: 14px;
    color: #666;
}

.draft-email-next-button {
    margin-top: 1rem;
    width: 100%;
}

.draft-email-context-input {
    width: 95%;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    height: 150px;
    resize: vertical;
}

.draft-email-generate-button {
    width: 100%;
}

.email-ui-container {
    max-width: 100%;
    background-color: #ffffff;
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    height: 100%;
}

.email-ui-header {
    background-color: #f3f4f6;
    padding: 1px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.email-ui-icon {
    margin-right: 0.5rem;
    color: #4b5563;
}

.email-ui-subject {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1f2937;
    flex-grow: 1;
    font-family: 'Inter', sans-serif;
}

.email-ui-body {
    padding: 1rem;
    overflow-y: auto;
    flex-grow: 1;
    font-family: 'Inter', sans-serif;
    max-height: 50vh;
    /* Ensures the body area doesn't exceed the modal height */
    position: relative;
}


.email-ui-content {
    color: #374151;
    white-space: pre-wrap;
    font-family: 'Inter', sans-serif;
    overflow: auto;
    max-height: 100%;
    text-align: left;
    font-size: 14px;
    /* Aligns the text to the left */
}


.email-ui-footer {
    padding: 1px 10px;
    background-color: #f3f4f6;
    display: flex;
    justify-content: space-between;
    font-family: 'Inter', sans-serif;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    /* Keeps it above any content that might scroll */
}

.custom-button-position {
  margin-right: 20px;
}

