.pdf-viewer-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #ffffff;
}

.pdf-viewer-controls {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 1000;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ffffff;
}

.pdf-viewer-wrapper {
    height: calc(100% - 50px);
    overflow-y: auto;
    padding: 20px;
    background-color: #ffffff;
    border: none;
}

.full-size-viewer {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
}

.pdf-document-viewer {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border: none;
    border-radius: 3px;
    overflow: hidden;
}

/* Custom CSS to hide the sidebar */
.pdf-document-viewer .rpv-default-layout__sidebar {
    display: none;
}

.pdf-document-viewer .rpv-default-layout__main {
    margin-left: 0;
}

/* Custom CSS for the toolbar */
.rpv-toolbar {
    background-color: #fcfcfc;
    /* Lighten the background color */
   
}

.rpv-toolbar .rpv-toolbar__item {
    color: #000000;
    /* Adjust the text color if needed */
}