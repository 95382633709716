.excel-viewer-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - -60px);
    /* Adjusted to leave some space below the header */
    width: 100%;
    margin-top: -10px;
    /* Moves the container slightly up towards the header */
    padding: 0;
}

.ag-theme-alpine {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    min-height: 0;
    min-width: 0;
    box-sizing: border-box;
}