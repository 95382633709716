/* MapExplorePage.css */

.map-explore-page-full {
    display: flex;
    width: 100%;
    height: 100vh;
}

.map-explore-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.map-explore-content {
    display: flex;
    flex-grow: 1;
}




/* Styles for the map container */
.map-container {
    flex: 70%;
    /* Takes up 70% of the flex container */
    overflow-y: auto;
    /* Allows scrolling if content overflows vertically */
    background-color: lightblue;
    /* For visibility, can be changed */
}


/* Styles for the chat panel container */
.map-chat-panel {
    flex: 30%;
    /* Takes up 30% of the flex container */
    height: 100%;
    /* Full height of the parent container */
    padding: 10px;
    background-color: white;
    /* For visibility, can be adjusted as needed */
}


/* Toggle button styles */
.toggle-chat-btn {
    position: relative; /* Positioned relative to the .map-explore-page-container */
    top: 50%; /* Align vertically */
    right: 10%; /* Position to the right */
    transform: translateY(-50%); /* Center vertically */
    z-index: 5000; /* Ensure it's above the other elements */
    cursor: pointer; /* Indicate the element is clickable */
    background: white; /* Remove button background */
    border: none; /* Remove button border */
    padding: 0; /* Remove button padding */
    border-radius: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 50px; /* Set the width of the button */
    height: 38px; /* Set the height of the button */
    display: none;
}



    /* You can directly style the SVGs if needed */
    .toggle-chat-btn svg {
        width: 30px; /* Example size, adjust as needed */
        height: auto; /* Example size, adjust as needed */
        fill: currentColor; /* SVG color inherits from font color */
        /* Add more styles if needed */
    }





/* Adjust the map container when chat is hidden */
.map-explore-page-container.no-chat .map-container {
    flex-grow: 0; /* Prevents the map from growing when chat is hidden */
    width: 100%; /* Takes full width when chat is hidden */
}

/* Hide the chat panel when the state is toggled */
.map-explore-page-container.no-chat .map-chat-panel {
    width: 0; /* Collapse the chat panel */
    padding: 0; /* Remove padding when collapsed */
    overflow: hidden; /* Hide the overflow when collapsed */
}


/* Position the button on the left edge of the chat panel */
.map-explore-page-container.chat-visible .toggle-chat-btn {
    right: 30%; /* Assuming the chat panel takes 30% of the container, adjust if needed */
    /* This will need to be adjusted if your chat panel width is different */
}





