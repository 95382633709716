/* OnboardingStep3.css */

.onboarding-step-3-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #00000000;
    border-radius: 8px;
}

.persona-description {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #ffffff;
    text-align: center;
    max-width: 80%;
}

.dropdown-container {
    margin-bottom: 20px;
    text-align: center;
}

.dropdown-container select {
    width: 100%;
    max-width: 500px;
    padding: 15px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    /* Add some space between the buttons */
    margin-top: 20px;
}

.onboarding-button {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
}

