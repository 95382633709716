.coming-soon-widget {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
    text-align: center;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




.coming-soon-badge {
    background-color: #fef3c7;
    color: #92400e;
    padding: 6px 12px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 0.875rem;
    font-weight: 500;
}