.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 35px;
    padding: 10px 20px;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #ffffff;
    /* Default background color */
    margin-top: 20px;
    margin-bottom: 35px;
    border: none;
    color: #000;
    /* Default text color */
    font-size: 15px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    
}

.button:hover {
    background-color: #e3e3e3;
    /* Darker shade on hover */
}



.button-small {
    padding: 9px 12px;
    font-size: 12px;
    height: 32px;
 
}

.button-medium {
    padding: 10px 20px;
    font-size: 15px;
    height: 35px;
    width: auto;
  
    /* Default height */
}

.button-large {
    padding: 14px 28px;
    font-size: 17px;
    height: 45px;
}



.dialog-delete-all-button {
    background-color: #ab1717 !important;
    min-width: 120px;
    color: #fff;
}


.dialog-cancel-all-button {
    min-width: 120px;
    color: #000000;
}



.button-black {
    background-color: black;
    /* Background color for black button */
    color: white;
    /* Text color for black button */
}



.button-red {
    background-color: rgb(176, 10, 10);
    /* Background color for black button */
    color: white;
    /* Text color for black button */
}


.button-red:hover {
    background-color: rgb(159, 6, 6);
    /* Background color for black button */
    color: white;
    /* Text color for black button */
}



.button-navy {
    background-color: rgb(19, 24, 62);
    /* Background color for black button */
    color: white;
    /* Text color for black button */
}


.button-grey {

    background-color: #333;
    /* Background color for black button */
    color: rgb(0, 0, 0);
    /* Text color for black button */
}



.button-teal {

    background-color: #1b3d62;
    /* Background color for black button */
    color: rgb(252, 252, 252);
    /* Text color for black button */
}



.button-blue {
    background-color: rgb(2, 19, 143);
    /* Background color for black button */
    color: white;
    /* Text color for black button */
}

.button-blue:hover {
    background-color: rgb(3, 14, 99);
    /* Background color for black button */
    color: white;
    /* Text color for black button */
}



.button-black:hover {
    background-color: #333;
    /* Darker shade on hover */
}

.text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: inherit;
    font-size: inherit;
    /* Inherit font size from button class */
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 20px;
    word-wrap: break-word;
    
}


.loading-text {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

/* Ensure text color is white for specific button styles */
.dialog-cancel-button,
.dialog-delete-button {
    color: white !important;
}




.delete-dialog-buttons {
    display: flex;
    justify-content: space-between; /* Or use justify-content: space-evenly; */
    width: 100%;
}




.button-green {
    background-color: #17812f;
    /* Green color */
    color: #fff;
    /* White text */
}

.button-green:hover {
    background-color: #218838;
    /* Darker green on hover */
}