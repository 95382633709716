

/* DefaultToastStyles.css */

.Toastify__toast-container {
    background-color: transparent !important; /* Set the background color to transparent */
    box-shadow: none !important; /* Remove the box shadow */
    border: none !important; /* Remove the border */
    color: transparent !important; /* Set the text color to transparent */
    font-size: 0; /* Set the font size to 0 to hide the text */
    padding: 0; /* Remove any padding */
    z-index: 10000000;
}


.Toastify__toast-container .Toastify__toast {
  background-color: initial !important; /* Reset the background color to initial */
  box-shadow: initial !important; /* Reset the box shadow to initial */
  border: initial !important; /* Reset the border to initial */
  padding: initial !important; /* Reset the padding to initial */
  z-index: 1000000000;
}

