/* DocumentIntelligence.css */
.doc-intel-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: white;
    position: relative;
    overflow: hidden;
}

.doc-intel-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 2.8%;
    position: relative;
    margin-top: 50px;
    
}

.doc-intel-content {
    display: flex;
    flex-direction: row;
    /* Ensure side by side layout */
    height: calc(100% - 50px);
    flex-grow: 1;
}


