.document-viewer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1000px;
    width: 58%;
    padding: 0px;
    box-sizing: border-box;
    overflow-y: hidden;
    border-right: 0.5px solid rgba(211, 211, 211, 0.2);
    background-color: white;
    margin-top: -20px;
}

.dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border: 2px dashed #add8e6; /* Light blue border color */
    border-radius: 8px;
    background-color: #f5f8ff; /* Light blue background color */
    cursor: pointer;
    text-align: center;
    position: relative;
}

.dropzone p {
    color: #555;
    font-size: 16px;
    margin-top: 20px;
}


.document-upload-svg {
    width: 25%;
    /* Adjust this value as needed */
    height: auto;
    cursor: pointer;
    /* Changes the cursor to a pointer when it hovers over the SVG */
    margin-bottom: 40%;
}


.upload-instructions {
    color: #393939;
    font-size: 16px;
    margin: 0;
    position: absolute;
    bottom: 58%;
    font-family: 'Inter', sans-serif;
    /* Move text up by adjusting this value */
}


.upload-instructions-2 {
    color: #393939;
    font-size: 16px;
    margin: 0;
    position: absolute;
    bottom: 53%;
    font-family: 'Inter', sans-serif;
    /* Move text up by adjusting this value */
}

/* Manages the loading overlay when a document is being uploaded */
.document-viewer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0, 0, 0.484);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90000;
}

.document-viewer-dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    width: 27%;
    height: 23%;
    border-radius: 2rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
}

.document-viewer-green-tick {
    position: absolute;
    top: 50%;
    left: -6%;
    transform: translate(-50%, -50%);
    fill: green;
    width: 15%;
    height: auto;
}

.document-viewer-dialog-container h2 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 1rem;
    text-align: center;
}

.document-viewer-dialog-container p {
    font-size: 0.7rem;
    font-weight: 300;
    margin-bottom: 2rem;
    text-align: center;
}

.document-viewer-loading {
    margin: 1rem 0;
}

/* Manages modal if user tries to upload when trial has ended */

/* Overlay */
.document-viewer-upgrade-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(211, 211, 211, 0.5);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90000;
}

.upgrade-plan-dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 26rem;
    height: 13rem;
    border-radius: 2rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
}

/* Style for the title in upgrade modal */
.upgrade-dialog-title {
    margin: 0.5rem 0;
    font-family: 'Inter', sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 60px;
  
}


.upgrade-tick-icon {
    width: 25px;
    /* Adjust the size of the icon as needed */
    height: auto;
    position: relative;
    top: 97px;
    right: 34px;
    
}

/* Style for the paragraph in upgrade modal */
.upgrade-dialog-paragraph {
    margin: 0.5rem 0;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
    /* Center the text */
}


/* Container for the new buttons */
.unique-upgrade-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin-top: 15px;
    margin-bottom: 55px;
    padding-bottom: 5px;
 
        align-items: center;
        justify-content: center;
}

/* Button for upgrading */
.unique-upgrade-button {
    gap: 10px;
    margin-right: 10px;
   
}

.unique-upgrade-button:hover {
    background-color: darkgreen;
}

/* Button for canceling */
.unique-cancel-button {
   gap: 10px;
   margin-left: 10px;
}

.unique-cancel-button:hover {
    background-color: black;
}

/* Container for the teaser SVG */
.upgrade-teaser-svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.upgrade-teaser-svg-container svg {
    width: 280px;
    height: auto;
}


.document-viewer-refresh-warning {
    margin-top: -10px;
   
}
