

.bol-extract {
    font-family: 'Inter', sans-serif;
    color: #333;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    font-size: 14px;
    /* Default font size */
}

.bol-extract .section {
    margin-bottom: 28px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.bol-extract .section h3 {
    font-size: 16px;
    /* Font size for section titles */
    margin-bottom: 10px;
    color: #333;
    padding-bottom: 5px;
}

.bol-extract .section p {
    margin: 5px 0;
    font-size: 14px;
    /* Default font size */
}

.bol-extract .section p strong {
    font-weight: bold;
}

/* Avoid the border on the last section */
.bol-extract .section:last-child {
    border-bottom: none;
}


.BOL-warning-icon {
    margin-left: 8px;
    vertical-align: middle;
    width: 20px;
    /* Adjust size as necessary */
    height: auto;
    /* Adjust size as necessary */
    fill: #e74c3c;
    /* Color of the warning icon */
    margin-bottom: 5px;
    margin-left: -3px;
}



.BOL-edit-button {
    background: none;
    border: none;
    color: #1b3d62;
    cursor: pointer;
    font-weight: bold;
   position: relative;
   bottom: 3px;
    margin-left: 5px;
    padding: 2px 6px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;

    font-size: 12px;
}



.new-field {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.new-field input {
    margin-right: 8px;
    flex: 1;
}

.new-field-button {
    background: none;
    border: none;
    color: #1b3d62;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 14px;
    margin-left: auto;
  
    margin-top: 20px;
    margin-right: auto;
    
    display: block;

    text-align: center;
    /* Centers the text within the button */
}

/* General styling for all placeholders */
input::placeholder {
    color: #aaa;
    /* Change the color of the placeholder text */
    font-size: 14px;
    /* Adjust the font size */
    font-style: italic;
    /* Make the text italic */
    opacity: 1;
    /* Ensures the opacity is consistent across browsers */
}

.BOL-confirmation-modal {
    background: #ffffff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    width: 30%;
    max-width: 450px;
    font-family: 'Inter', sans-serif;
    position: absolute;
    z-index: 10;
}

.BOL-confirmation-modal p {
    margin: 0;
    text-align: center;
    font-weight: bold;
    /* Make the text bold */
}

.BOL-confirmation-modal .button-container {
    display: flex;
    justify-content: space-around;
    margin-top: -10px;
    margin-bottom: -30px;
  
}


.BOL-add-section-button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
}

.delete-field-icon {
   height: auto;
   width: 25px;
}


